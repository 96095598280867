import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import CountUp from 'react-countup';

import { Link } from "react-router-dom";
import HomeUrl from '../assets/images/home-border.png';
export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          id: 'service1',
          icon: 'first',
          
          title: "Recruitment",
          description: "Future talent sourcing with cutting-edge assessments. A versatile tool for internal and external recruitment that offers a wealth of information about potential candidates."
        },
        {
          id: 'service2',
          icon: 'second',
          title: "Employee Assessment",
          description: '. Your team always up to standards with cutting-edge online tests that provide actionable insights.'
        },
        {
          id: 'service3',
          icon: 'third',
          title: "Exams",
          description: 'Big exams in a fair environment. Remote assessments are an outstanding alternative to traditional exams, especially when they are supported by sophisticated online tools driven by Big data, AI machine learning, cybersecurity technologies'
        },
        {
          id: 'service4',
          icon: 'fourth',
          title: "Training",
          description: 'Online assessments for all training purposes Measure training efficiency with an affordable, intuitive online test builder. Make sure you get value for your money.'
        },
        {
          id: 'service5',
          icon: 'fifth',
          title: "Salesforce",
          description: 'Sales team ready for any challenge Manage your sales team and provide them with the skills and knowledge necessary to succeed. '
        },
        {
          id: 'service6',
          icon: 'nine',
          title: "Customer Service",
          description: 'Turn your customer service into experts Periodic assessments of your customer service representatives optimize their performance and help them achieve more. '
        },
      ],
      counters: [
        {
          id: '1',
          extraclass: '',
          start: 55,
          end: 190,
          title: "Countries",
          description: 'Technology deployed in over 190 countries.'
        },
        {
          id: '2',
          extraclass: 'pt-3',
          start: 0,
          end: 3,
          hi: "M",
          title: "Users",
          description: 'Uver 3M users have used these technologies across partners’ channels'
        },
        {
          id: '3',
          extraclass: 'pt-3',
          start: 25,
          end: 200,
          hello: "K",
          title: "Test",
          description: 'Across integrated portals for education and businesses, over 200k takes test daily using this technology every day'
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-services" id="services">
          <Container>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  {/* <h3 className="title-heading mt-4">Provide Awesome Services </h3> */}
                  <h3 className="title-heading mt-4">One Seamless Test Hub. Do More for Less</h3>
                  {/* <p className="text-muted f-17 mt-3">Vivamus ac nulla ultrices laoreet neque mollis mi morbi
                  elementum mauris
                            sit amet arcu <br /> fringilla auctor In eleifend maximus nisi sed vulputate.</p> */}
                  <p className="text-muted f-17 mt-3">We will help you elevate your testing projects as you join the league of global leading organizations as well transforming your online test and assessment to success stories. This comes with opportunity to do more for less while covering wide range of your service and technology gap needs. </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              {/* Render Footer Link */}
              {this.state.services.map((item, key) => (
                <Col lg={4} key={key}>
                  <div className="services-box p-4 mt-4">
                    <div className="services-icon bg-soft-primary">
  
                    {/* <img src={require('../assets/images/' + item.icon + '.png')} width="25" alt="hi" /> */}
                    <img src={require(`../assets/images/${item.icon}.png`).default} alt="hi"/>
                      {/* <i className={"mdi text-primary " + item.icon}></i> */}
                    </div>

                    <h5 className="mt-4">{item.title}</h5>
                    <p className="text-muted mt-3">{item.description}</p>

                    <div className="mt-3">
                      <Link to="#" className="text-primary f-16">Learn More <i className="mdi mdi-arrow-right ml-1"></i></Link>
                    </div>

                  </div>
                </Col>
              ))}
            </Row>
            <Row className="align-items-center mt-5 pt-4" id="counter">
              <Col lg={6}>
                <div className="pr-4 mt-4">
                  {/* <p className="text-uppercase">why choose us </p> */}
                  <p className="text-uppercase">Why PT Portal?</p>
                  {/* <h3>Boots Your Website Thamza!</h3> */}
                  <h3>“We understand the challenge, know there is a better way to test and offer seamless and ecofriendly solutions.”</h3>
                  {/* <p className="text-muted mt-3">Morbi at erat et tortor tempor sodales non eu lacus Donec at gravida
                  nunc vitae volutpat libero fusce sit amet mi quis ipsum tempus
                  gravida in quis felis Aliquam fringilla tempus scelerisque Phasellus viverra tincidunt
                  suscipit. Praesent non arcu vitae augue
                            aliquam eget vitae Pellentesque sem mauris porta eget ultrices eros.</p> */}
                  <h2>The Challenge</h2>
                  <p className="text-muted mt-3">We understand the global challenge and the local dimensions. What works yesterday no longer works today’s fast changing work and learning environment. The conventional way of conducting test, exams, assessment etc.  failing to provide quick and detailed feedback. Downtimes affect decision-making and ultimately cost organizations money.</p>
                  <h2>Better Way to Test</h2>
                  <p className="text-muted mt-3">We know there is better way to test and other form of assessment. This includes Automatic Grading, Comprehensive Insights and Analytics, Remote Assessments, Easy to Archive, Eco-friendly. For instance, with every usage of 8000 pages of paper test, a tree is gone. Applied to some local realities, a leading exam body in Nigeria which pioneered an online test is saving the environment an average of 3000 trees every year. This is commendable, and we are committed to create a seamless to other willing organization to do same in a very seamless, effective and echo-friendly manner.</p>
                  <h2>Solution</h2>
                  <p className="text-muted mt-3">PT Portal therefore offers a comprehensive insight available moments after the assessment. Accurate and personalized results are available to both test owners and respondents as each case demands or requires. The solution is note only seamless, it is also secure. It comes with enterprise-grade security and data protection. This ensures your respondents' personal data and assessment results are perfectly safe with us. </p>
                  {/* <div className="mt-4 pt-1">
                    <Link to="#" className="btn btn-outline-primary">Discover More</Link>
                  </div> */}
                </div>
              </Col>
              <Col lg={5} className="offset-lg-1">
                <div className="counter-box">
                  {this.state.counters.map((counteritem, key) => (
                    <div className={ (counteritem.id !== '1') ? 'mt-4 pt-3' : 'mt-4' } key={key}>
                      <div className="media">
                        <div className="count-box bg-soft-primary text-center">
                          <h3 className="counter-count mb-1 text-primary"> 
                          <CountUp className="counter-value" delay={2} start={counteritem.start} end={counteritem.end} />{" "}
                            <span className="count-plus text-primary"> {counteritem.hi}</span>
                            <span className="count-plus text-primary"> {counteritem.hello}</span>
                            <span className="count-plus text-primary"> +</span></h3>
                          <p className="text-uppercase text-muted mb-0 f-17"><b>{counteritem.title}</b> </p>
                        </div>
                        <div className="media-body pl-4">
                          <p className="text-muted mb-0 mt-3">{counteritem.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
