import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

import HomeUrl from '../../assets/images/home-border.png';
import Logo from '../../assets/images/logo.png';
import Img from '../../assets/images/features/img-2.png';

class Section extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
 
  openModal () {
    this.setState({isOpen: true})
  }

  render() {
    return (
      <React.Fragment>
        <section className="bg-home bg-light" id="home">
            <form className="d-flex" style={{justifyContent: "space-around", marginTop: "-150px"}}>
                  <div>
                            <h1 style={{}}>Here to take a test?</h1>
                            <p>No registration required. Enter your access code and start.</p>
                  </div>
                   <div>
                      <input placeholder="Enter the access code" type={"number"} style={{height:"50px",marginRight: "-200px",width: "400px", border: "1px solid white", borderRadius: "10px"}}/>
                    </div> 
                    <div>
                      <button style={{width: "200px", background: "lightgreen"}}>Start your test</button>
                    </div>
                    
            </form>
          <div className="home-center">
            <div className="home-desc-center">
              <Container>
                <Row className="align-items-center">
                  <Col lg={6}>
                    
                    <div className="home-content">
                      <img src={Logo}  style={{width: "350px",height: "200px"}} alt=""/>
                      <br/>
                      <p className="mb-0">Secure and seamless digital test</p>
                      {/* <img src={HomeUrl} height="15" alt="" /> */}
                      {/* <h1 className="home-title mt-4">Grow Your <br /> Business Google Ads & Instagram Ads</h1>
                      <p className="text-muted mt-4 f-20">Vestibulum egestas magna ut aliquet sodales nunc
                      fermentum ligula
                                    quis tidunt vitae massa.</p> */}
                      <h1 className="home-title mt-4">Test. Assessment.<br/>
                        Exams. Quizzes. <br/>Get rid of the stress. We help to make success out of your story
                          </h1>
                          <p className="text-muted mt-4 f-20">Join global network of seamless skills and knowledge evaluation solution, serving 2.5M+ business and educational users worldwide.</p>
                      <div className="mt-4 pt-2">
                        <Link to="#" className="btn btn-primary mr-3">Contact Us</Link>{" "}
                        {/* <Link to="#" className="video-play-icon text-dark" onClick={this.openModal}><i className="mdi mdi-play-circle-outline text-dark mr-2"></i>Watch Intro Video</Link> */}
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="home-img">
                      <div className="animation-1"></div>
                      <div className="animation-2"></div>
                      <div className="animation-3"></div>
                      <img src={Img} className="img-fluid" alt="" style={{width: "450px", height:"450px"}}/>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <ModalVideo
              channel="vimeo"
              isOpen={this.state.isOpen}
              videoId="99025203"
              onClose={() => this.setState({ isOpen: false })}
            />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
